import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styles from './teachers.module.css'
import Layout from '../components/layout'
import Img from 'gatsby-image'

class TeachersIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const teachers = get(this, 'props.data.allContentfulTeacher.edges')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle}/>
          <div className={styles.hero}>
            Teachers
          </div>
          <div className="wrapper">
            <ul className="article-list">
              {teachers.map(({ node }) => {
                return (
                  <li key={node.id} className={styles.teacher}>
                    <div className={styles.photo}><Img alt="" fluid={node.photo.fluid} style={{width: "100px"}}/></div>
                    <div>
                      <div>{node.lastname} {node.firstname}</div>
                      <div>Matières enseignées: {node.subjects.join(', ')}</div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TeachersIndex

export const pageQuery = graphql`
  query TeachersIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulTeacher(sort: { fields: [lastname], order: DESC }) {
      edges {
        node {
          id
          firstname
          lastname
          subjects
          photo {
            fluid(maxWidth: 100, maxHeight: 120, resizingBehavior: CROP) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
